import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/utils/request';
export default {
  name: 'Index',
  data: function data() {
    return {
      sheetName: null,
      sheetTableName: null,
      dialogVisible: false,
      tableNames: [],
      dataTypeOptions: [{
        value: 1,
        label: '字符串'
      }, {
        value: 2,
        label: '数字'
      }, {
        value: 3,
        label: '时间戳'
      }, {
        value: 4,
        label: '百分比'
      }],
      uploadFile: null,
      fileList: [],
      excelTemplate: null,
      uploadFileMetadata: null,
      tabIndex: 0,
      sheetIndex: 0,
      tableMetadata: [],
      tableHeight: 492
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var self = _this;
      _this.tableHeight = document.body.offsetHeight;
      console.log("tableHeight:" + _this.tableHeight);
      // 通过捕获系统的onresize事件触发去改变原有的高度
      window.onresize = function () {
        self.tableHeight = document.body.offsetHeight;
        console.log("tableHeight:" + self.tableHeight);
      };
    });
  },
  computed: {
    canEdit: function canEdit() {
      try {
        var clientId = this.excelTemplate.clientId;
        var state = this.$store.state;
        var user = state.user;
        return clientId == user.clientId;
      } catch (e) {
        return false;
      }
    },
    action: function action() {
      if (this.uploadFileMetadata) {
        return 'import-data';
      }
      return 'import-data/excel-file-metadata';
    },
    mapBusinessTableHeaderOptions: function mapBusinessTableHeaderOptions() {
      var templatesColumnList = this.getTemplatesColumnList();
      return [{
        description: '请选择...',
        excelColumnIndex: -1
      }].concat(templatesColumnList.filter(function (v) {
        return v.excelColumnIndex == -1;
      }));
    }
  },
  created: function created() {
    console.log(this.$route);
    this.getExcelTemplate(this.$route.query.id);
    this.getTables();
    this.getTablesMetadata();
  },
  watch: {
    tabIndex: function tabIndex(val, oldVal) {
      this.sheetIndex = parseInt(val);
    }
  },
  methods: {
    onRequiredChange: function onRequiredChange(value, row) {
      if (value && !row.comment) {
        row.comment = this.$t('rules.common.required');
      }
    },
    onChangeColumn: function onChangeColumn(value, row) {
      row.tableMetadata.column.forEach(function (item) {
        if (item.name === value) {
          row.description = item.label;
          row.columnMetadata = item;
          row.propertyId = item.value;
          return;
        }
      });
    },
    onChangeTable: function onChangeTable(value, row) {
      this.tableMetadata.forEach(function (item) {
        if (item.table === value) {
          row.tableMetadata = item;
          return;
        }
      });
    },
    onDescChange: function onDescChange(value, row) {
      console.log(arguments);
      row.defaultDescription = value;
    },
    confirmSheet: function confirmSheet() {
      this.dialogVisible = false;
      this.excelTemplate.templateSheets.push({
        name: this.sheetName,
        tableName: this.sheetTableName,
        orderId: null,
        templateColumnList: []
      });
      this.submit();
      this.tabIndex = this.excelTemplate.templateSheets.length - 1 + '';
    },
    deleteSheet: function deleteSheet(index) {
      var _this2 = this;
      this.$confirm('是否要删除该Sheet?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(function () {
        _this2.excelTemplate.templateSheets.splice(index, 1);
        _this2.sheetIndex = 0;
      }).catch(function () {});
    },
    addSheet: function addSheet() {
      var _this3 = this;
      this.$confirm('是否要添加Sheet?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(function () {
        _this3.dialogVisible = true;
      }).catch(function () {});
    },
    back: function back() {
      this.$router.back();
      console.log(this.tableData);
    },
    onTabClick: function onTabClick() {},
    formatter: function formatter(row, column, cellValue, index) {
      console.log(arguments);
      return row[column.index];
    },
    getExcelTemplate: function getExcelTemplate(id) {
      var _this4 = this;
      if (!id) {
        id = 1;
      }
      request({
        url: 'data-template/' + id + '?edit=1',
        method: 'get'
      }).then(function (response) {
        if (response.status === 200) {
          _this4.excelTemplate = response.data;
        }
      });
    },
    getTables: function getTables() {
      var _this5 = this;
      request({
        url: 'data-template/tables',
        method: 'get'
      }).then(function (response) {
        if (response.status === 200) {
          _this5.tableNames = response.data;
        }
      });
    },
    getTablesMetadata: function getTablesMetadata() {
      var _this6 = this;
      request({
        url: 'data-template/tables/metadata',
        method: 'get'
      }).then(function (response) {
        if (response.status === 200) {
          _this6.tableMetadata = response.data;
        }
      });
    },
    addColumn: function addColumn() {
      var templateColumnList = this.excelTemplate.templateSheets[this.sheetIndex].templateColumnList;
      for (var i = templateColumnList.length - 1; i >= 0; i--) {
        if (!templateColumnList[i].id && !templateColumnList[i].propertyName) {
          this.$message.warning('数据未填写完整，不能添加');
          return;
        }
      }
      var max_order = 0;
      templateColumnList.forEach(function (item) {
        max_order = Math.max(max_order, item.order ? item.order : 0);
      });
      templateColumnList.push({
        clientPk: null,
        dataType: null,
        defaultDescription: null,
        description: null,
        dynamicProperty: null,
        excelColumnIndex: null,
        id: null,
        name: null,
        order: max_order + 10,
        propertyId: null,
        required: null,
        valid: true
      });
    },
    submit: function submit() {
      var _this7 = this;
      var excelTemplate = this.excelTemplate;
      var name = excelTemplate.name;
      if (!name) {
        this.$message.warning('请输入模块名称');
        return;
      }
      var moduleName = excelTemplate.moduleName;
      if (!moduleName) {
        this.$message.warning('请输入业务名称');
        return;
      }
      request({
        url: 'data-template/save',
        method: 'post',
        data: this.excelTemplate
      }).then(function (res) {
        if (res.status === 200) {
          _this7.$message({
            message: '操作成功',
            type: 'success'
          });
          _this7.getExcelTemplate(_this7.$route.query.id);
        }
      });
    },
    onSelectFile: function onSelectFile() {
      console.log('-----onSelectFile-----');
      console.log(arguments);
      console.log(this);
    },
    selectUploadFile: function selectUploadFile() {
      console.log(this.$refs.upload);
      this.$refs.upload.click();
    },
    onChange: function onChange(file, fileList) {
      console.log('-----onChange-----');
      console.log(file);
      console.log(fileList);
    },
    onSuccess: function onSuccess(response, file, fileList) {
      console.log('-----onSuccess-----');
      this.uploadFileMetadata = response.data;
      this.uploadFile = file;
    },
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
    },
    beforeUpload: function beforeUpload(file) {
      console.log(file);
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    getTemplatesColumnList: function getTemplatesColumnList() {
      try {
        var templateColumnList = this.excelTemplate.templateSheets[this.sheetIndex].templateColumnList;
        return templateColumnList;
      } catch (e) {}
      return [];
    },
    getSelectValue: function getSelectValue(index, item) {
      var templatesColumnList = this.getTemplatesColumnList();
      for (var i = 0, length = templatesColumnList.length; i < length; i++) {
        if (templatesColumnList[i].excelColumnIndex == index) {
          return templatesColumnList[i].description;
        }
      }
      return '请选择...';
    },
    onSelectChange: function onSelectChange(ufm_item, index, option) {
      console.log(arguments);
      var templatesColumnList = this.getTemplatesColumnList();
      for (var i = 0, length = templatesColumnList.length; i < length; i++) {
        if (templatesColumnList[i].excelColumnIndex == index) {
          templatesColumnList[i].excelColumnIndex = -1;
        }
      }
      if (option.excelColumnIndex == -1) {
        option.excelColumnIndex = index;
      }
      console.log(this.excelTemplate);
    }
  }
};